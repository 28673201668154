import { action, makeObservable, observable } from "mobx";

import { SearchParamsStorage } from "elements/useSearchParamsStorage";
import { Project } from "models/project/project";

import { FactorAnalysisSettingsModel } from "./settings/model";
import { calculate, CalculateSettings, FACTOR_NAMES, Factors } from "./services";

class FactorAnalysisModel {
  public readonly settings: FactorAnalysisSettingsModel;
  public factors: FactorOrder | null | undefined = null;

  constructor(project: Project, storage: SearchParamsStorage) {
    this.settings = new FactorAnalysisSettingsModel(project, storage);
    if (this.settings.isValid) {
      this.calculate();
    }

    makeObservable(this, {
      factors: observable.ref,
      calculate: action,
    });
  }

  public calculate = async () => {
    this.factors = undefined;
    const settings = this.settings.calculationSettings;
    if (settings === null) {
      this.factors = null;
      return;
    }
    try {
      const factors = await calculate(settings);
      this.factors = new FactorOrder(settings, factors);
      this.settings.save();
    } catch (e: any) {
      this.factors = null;
      const err = await e.json();
      if (err.detail.status === "recalculate") {
        this.settings.badScenarios.add(err.detail.scenario);
      }
    }
  };
}

type Factor = {
  key: string;
  name: string;
  scenario: number;
  usc: number;
  npv: number;
  npvDelta: number | undefined;
  npvDeltaRelative: number | undefined;
};

class FactorOrder {
  #order: (keyof Factors)[] = ["basis", "usc", "production", "opex", "capex", "other", "comparison"];

  constructor(public readonly settings: CalculateSettings, public readonly factors: Factors) {}

  public get length(): number {
    return this.#order.length;
  }

  public getFactor(index: number): Factor | undefined {
    const key = this.#order[index];
    if (key === undefined) {
      return undefined;
    }
    const scenario = key === "comparison" ? this.settings.comparedScenario : this.settings.basisScenario;

    const usc = key === "usc" ? this.settings.comparedUsc : this.settings.basisUsc;
    const npv = this.factors[key];

    const prevKey = this.#order[index - 1];
    const npvPrev = prevKey && this.factors[prevKey];
    const npvDelta = npvPrev && npv - npvPrev;
    const npvDeltaRelative = npvDelta && (npvDelta / Math.abs(npvPrev)) * 100;

    return {
      key,
      name: FACTOR_NAMES[key],
      scenario,
      usc,
      npv,
      npvDelta,
      npvDeltaRelative,
    };
  }

  *[Symbol.iterator](): Iterator<Factor> {
    for (let i = 0; i < this.length; ++i) {
      yield this.getFactor(i)!;
    }
  }
}

export type { FactorOrder };
export { FactorAnalysisModel };
