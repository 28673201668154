import { FC, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { Empty, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { Format } from "elements/format/format";
import { Loader } from "elements/loader";
import { useFact } from "models/project/fact/fact";

import { FactorAnalysisModel } from "../factorAnalysisModel";

import { type DRow, FactorAnalysisTableModel } from "./model";

import cn from "./table.module.less";

type FactorAnalysisTableProps = {
  model: FactorAnalysisModel;
};

const useColumns = (): ColumnRaw<DRow>[] => {
  const fact = useFact()!;

  return useMemo<ColumnRaw<DRow>[]>(
    () => [
      {
        title: "Сценарий",
        dataKey: "scenario",
        width: { min: 200, max: 500, competitiveness: 2 },
        render: (id: number | null) => {
          if (id === null) {
            return <div />;
          }
          const scenario = fact.forecasts.at(id);
          return <Format>{scenario && scenario.title}</Format>;
        },
      },
      {
        title: "ЕСУ",
        dataKey: "usc",
        width: { min: 200, max: 500, competitiveness: 2 },
        render: (id: number) => {
          const usc = fact.ecyStore.at(id);
          return <Format>{usc && usc.title}</Format>;
        },
      },
      {
        title: "Фактор",
        dataKey: "factor",
        width: { min: 200, max: 500, competitiveness: 2 },
        render: (v) => <Format>{v}</Format>,
      },
      {
        title: "NPV, млн $",
        dataKey: "npv",
        width: { min: 120, max: 200, competitiveness: 1 },
        render: (v) => <Format>{v}</Format>,
      },
      {
        title: "ΔNPV, млн $",
        dataKey: "npvDelta",
        width: { min: 120, max: 200, competitiveness: 1 },
        onCell: ({ value }) => (value && value.npvDelta !== null ? { className: value.npvDelta > 0 ? cn.positive : cn.negative } : {}),
        render: (v) => <Format sign>{v}</Format>,
      },
      {
        title: "ΔNPV, %",
        dataKey: "npvDeltaRelative",
        width: { min: 120, max: 200, competitiveness: 1 },
        onCell: ({ value }) =>
          value && value.npvDeltaRelative !== null ? { className: value.npvDeltaRelative > 0 ? cn.positive : cn.negative } : {},
        render: (v) => <Format sign>{v}</Format>,
      },
    ],
    [fact]
  );
};

const FactorAnalysisTable: FC<FactorAnalysisTableProps> = observer(({ model }) => {
  const columns = useColumns();
  const store = useMemo(() => new FactorAnalysisTableModel(model), [model]);
  const table = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {},
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );
  if (!model.factors) {
    return (
      <div className={cn.emptyContainer}>
        {model.factors === null ? <Empty description={<Typography.Text>Нет данных для отображения</Typography.Text>} /> : <Loader size="large" />}
      </div>
    );
  }
  return (
    <TableContextProvider value={table}>
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { FactorAnalysisTable };
