import { useMemo } from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import "dayjs/locale/ru";

import { PipesModel } from "../../models/pipes";

import cn from "./useParamPipesTableStore.module.less";

const useColumns = (mode: "prod" | "inj"): Column[] => {
  const { catalog, range } = useInfrastructure()!;
  return useMemo(
    () => [
      {
        title: "Название участка",
        width: { min: 170, max: 200, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        type: "string",
        editable: true,
        onCell: () => ({ className: classNames(cn.tableFirstCell, cn.tableCell) }),
      },
      {
        title: "Вид трубопровода",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "kind",
        type: "select",
        options: catalog[mode === "inj" ? "waterPipesSelector" : "oilPipesSelector"]!,
        editable: true,
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Категория",
        width: { min: 120, max: 160, competitiveness: 1 },
        dataKey: "category",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Внешний диаметр, мм",
        width: { min: 140, max: 140, competitiveness: 1 },
        dataKey: "diameterOuter",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Толщина стенки, мм",
        width: { min: 120, max: 140, competitiveness: 1 },
        dataKey: "thickness",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Шерохов-ть стенки, мм",
        width: { min: 130, max: 140, competitiveness: 1 },
        dataKey: "roughness",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Дата ввода",
        width: { min: 130, max: 140, competitiveness: 1 },
        dataKey: "startedAt",
        type: "date",
        renderFormat: "month",
        start: dayjs().year(1970),
        end: dayjs().year(range.to),
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell, cn.date) }),
      },
      {
        title: "Дата выбытия",
        width: { min: 150, max: 140, competitiveness: 1 },
        dataKey: "finishedAt",
        type: "date",
        renderFormat: "month",
        editable: true,
        start: (tableItem: any) => dayjs(tableItem.value.startedAt),
        end: dayjs().year(range.to),
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell, cn.date) }),
      },
      {
        title: "Узел начала",
        width: { min: 102, max: 120, competitiveness: 1 },
        dataKey: "firstNodeTitle",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Координаты узла начала, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        dataKey: "firstNodeCoords",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Альтитуда узла начала, м",
        width: { min: 140, max: 150, competitiveness: 1 },
        dataKey: "firstNodeAltitude",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Узел конца",
        width: { min: 102, max: 120, competitiveness: 1 },
        dataKey: "secondNodeTitle",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Координаты узла конца, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        dataKey: "secondNodeCoords",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Альтитуда узла конца, м",
        width: { min: 140, max: 150, competitiveness: 1 },
        dataKey: "secondNodeAltitude",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Протяженность, м",
        width: { min: 180, max: 200, competitiveness: 1 },
        dataKey: "length",
        type: "number",
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Коррект. коэф-т на трение",
        width: { min: 160, max: 180, competitiveness: 1 },
        dataKey: "frictionCorrectionFactor",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Уд. стоимость строительства, тыс руб / км",
        width: { min: 240, max: 250, competitiveness: 1 },
        dataKey: "unitConstructionCost",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Стоимость строительства, тыс руб",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "cost",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Учет реконструкции",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "isReconstruction",
        onCell: () => ({ className: cn.tableCell }),
        type: "set",
        editable: true,
      },
      {
        title: "Уд. стоимость реконструкции, тыс руб /км",
        width: { min: 240, max: 250, competitiveness: 1 },
        dataKey: "unitReconstructionCost",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Стоимость реконструкции, тыс руб",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "costReconstruction",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Период реконструкции, года",
        width: { min: 190, max: 220, competitiveness: 1 },
        dataKey: "periodReconstruction",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
    ],
    [catalog, range, mode]
  );
};

const useParamPipesTableStore = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure();
  const columns = useColumns(mode);
  const store = useMemo(() => new PipesModel(infrastructure, mode), [infrastructure, mode]);
  return { columns, store };
};

export { useParamPipesTableStore };
