const DEFAULT_BAR = 1.01325;

const convertPressureFn = (): {
  barToAtm: (bar?: number | null) => number | undefined;
  atmToBar: (mpa?: number | null) => number | undefined;
} => {
  const barToAtm = (bar?: number | null) => {
    if (!bar) return;
    if (bar === 0) return 0;
    return bar / DEFAULT_BAR;
  };
  const atmToBar = (atm?: number | null) => {
    if (!atm) return;
    if (atm === 0) return 0;
    return atm * DEFAULT_BAR;
  };

  return { barToAtm, atmToBar };
};

const convertPressure = convertPressureFn();

export { convertPressure };
