import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const ForecastOutline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fact = useFact()!;
  const forecast = useForecast();
  const params = useParams();
  const paramsParsed = useMainRouterParams();

  const defaultForecast = fact.forecasts.first;

  useEffect(() => {
    if (forecast?.id) {
      global.logger.setScenarioId(forecast.id);
    } else if (forecast === null && defaultForecast) {
      const pathSegments = location.pathname.split("/");
      pathSegments[2] = defaultForecast.id.toString();
      const path = pathSegments.join("/");

      navigate(`${path}${location.search || ""}`);
    }
  }, [defaultForecast, forecast, location, navigate]);

  if (forecast === null) {
    if (paramsParsed.scenario === undefined) {
      return <FullScreenEmpty>Страница {params.scenario} не найдена</FullScreenEmpty>;
    }
    if (!defaultForecast) {
      return <FullScreenEmpty>Не удалось найти сценарии в проекте</FullScreenEmpty>;
    }
  }
  return <Outlet />;
};

export { ForecastOutline };
