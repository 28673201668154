import { when } from "mobx";
import { objectToSnake } from "ts-case-convert";

import { ComparisonValues, extractComparisonValues } from "features/comparison/compareScenario/utils/extractValues";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { Metric, MetricTree, riseMetrics } from "services/finance/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import { backendStorage } from "../backendStorage";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { constantsSchema } from "./constants";
import { extractUSCModels } from "./ecy";
import { investSchema } from "./invest";
import { metCoefs } from "./metCoefs";
import { operatingSchema } from "./operating";
import { reconstruction } from "./reconstruction";
import type { Input, Output, USCOutput, USCOutputWithInjectedFields } from "./types";
import { replaceYearsRange, unchangedPaths } from "./utils";
import { deposit, drilling } from "./well";

export const WELLS_KEY_STEP = 1000;

type Schema = {
  schema: Metric[];
};

type Result = {
  schema_operation: Schema;
  schema_invest: Schema;
  schema_usc: Schema;
  schema_cashflow: Schema;
  schemaOperation: MetricTree[];
  schemaInvest: MetricTree[];
  schemaUSC: MetricTree[];
  schemaCashflow: MetricTree[];
  schemaWells: MetricTree[];
  schema_well_summary_response: MetricTree[];
  result: Schema;
};

type OptimizationWellMetric = Metric & {
  wellId?: number;
};

type ResultPack = {
  total: Result;
  [licenseRegionId: number]: Result;
};

type CalculationExecNormalResult = {
  unchanged: string[];
  changed: string[];
  delay: number;
  calculated: Map<number, ResultPack>; // usc_uuid -> Result
};

const adjustWellsSchema = (
  data: Array<{ property: { name: string; id: number }; schema: Array<Metric> }>
): OptimizationWellMetric[] =>
  data
    .map(({ property, schema }, id): Metric[] => {
      const wellKey = (id + 1) * WELLS_KEY_STEP;
      return [
        {
          key: wellKey,
          title: property.name,
          wellId: property.id,
          parent: null,
          unit: null,
        },
        ...schema.slice(1).map((metric: any) => ({
          ...metric,
          parent: (metric.parent_id ?? 0) + wellKey,
          key: metric.id + wellKey,
        })),
      ];
    })
    .flat();

const riseSchema = (data: any, schemaName: string) => {
  // Чистим то, что не имеет потомков и значений
  let dataset: any[] = data[schemaName].schema;
  let dataSize: number | undefined;
  // если глава полностью очистилась то нужно чтобы и родитель был зачищен
  while (dataSize !== dataset.length) {
    dataSize = dataset.length;
    const hasChildren = new Set(dataset.map(({ parent_id }) => parent_id));
    dataset = dataset.filter(
      ({ id, values }) =>
        hasChildren.has(id) ||
        (Array.isArray(values) && values.length > 0 && values.find((v) => v !== null) !== undefined)
    );
  }
  return riseMetrics(dataset.map((v: any) => ({ ...v, key: v.id, parent: v.parent_id })));
};

const provideProperties = (data: Forecast) =>
  ({
    start: data.wholeRange.from,
    stop: data.wholeRange.to - 1,
    plan_year: data.range.from + 1,
    infrastructural: data.fact.project.isInfrastructure,
    preferences: data.taxDiscountTitle!,
    losses_transfer: data.fact.project.isLossTransfer,
    ndd_asset_algorithm: data.fact.project.isAssetAlgoNdd,
  } as typeof CALCULATE_PREDEFINE.properties);

const provideRequest = (data: Forecast): Input => {
  const request: Input = {
    ...CALCULATE_PREDEFINE,
    schema_reconstruction: undefined,
  };
  const stages = [
    () => {
      request.properties = provideProperties(data);
    },
    () => {
      request.schema_reconstruction = reconstruction(data);
      request.schema_drilling = drilling(data);
      request.deposit = deposit(data);
      request.unit_costs = data.operatingCosts.optionedScalars!.data.map((v) => v.totalDump);
      request.met_coefs = metCoefs(data);
    },
    () => {
      request.usc_model = extractUSCModels(data);
    },
    () => {
      request.lz = data.licenseRegions.data.map((lr) => {
        let props;
        if (lr.ndd) {
          props = {
            ndd_year: lr.ndd.transitionYear,
            ndd_group: lr.ndd.ndd.group,
            prom_year: undefined,
          } as any;
        }
        const schema_operation = operatingSchema(data, lr.id);
        const schema_invest = investSchema(data, lr.id);
        return {
          id: lr.id,
          name: lr.title,
          props,
          schema_operation,
          schema_invest,
        };
      });
    },
    () => {
      request.constants = constantsSchema(data) as any;
    },
    () => {
      request.schema_reconstruction = reconstruction(data) as any;
    },
  ];
  for (const stage of stages) {
    stage();
  }
  return request;
};

type WrappedUSCResult = {
  total: USCOutputWithInjectedFields;
  [licenseRegionId: number]: USCOutputWithInjectedFields;
};

const wrapSingle = (uscModel: any, economics: any, output: USCOutput): USCOutputWithInjectedFields => {
  // WARN: need deep copy ????
  const result: USCOutputWithInjectedFields = { ...output } as any;

  result.schema_operation = economics.schema_operation;
  result.schema_invest = economics.schema_invest;
  result.schemaOperation = riseSchema(economics, "schema_operation");
  result.schemaInvest = riseSchema(economics, "schema_invest");

  result.schema_usc = uscModel.schema_usc;
  result.schemaUSC = riseSchema(uscModel, "schema_usc");
  result.schemaCashflow = riseSchema(result, "schema_cashflow");
  result.schemaWells = [
    ...riseSchema(result, "schema_well_summary_response"),
    ...riseMetrics(adjustWellsSchema(result.schema_well_production)),
  ];
  return result;
};

const wrapBackendUSCResult = (uscModels: Map<number, { schema_usc: any }>, uscOutput: USCOutput): WrappedUSCResult => {
  const uscModel = uscModels.get(uscOutput.usc_uuid)!;
  const totalEconomics = uscOutput.total_schema ?? uscOutput.lz_schema[0];
  const byLZ = uscOutput.lz_schema.map(({ id, ...lzEconomics }) => [id, wrapSingle(uscModel, lzEconomics, uscOutput)]);

  return {
    total: wrapSingle(uscModel, totalEconomics, uscOutput),
    ...Object.fromEntries(byLZ),
  };
};

type CachedValues = {
  isWithoutGtm: boolean;
  [uscId: number]: {
    comparisonValues: ComparisonValues;
  };
};

type CalculationSaveMeta = { ts: Date; forecastId: number; cached: CachedValues };

const loadCalculationMeta = async (fc: Forecast): Promise<CalculationSaveMeta | null> => {
  const data = await backendStorage.getItem<CalculationSaveMeta | null>(
    "economic_result_meta",
    fc.fact.projectId,
    fc.id
  );
  if (data === null) {
    return null;
  }
  return {
    ...data,
    ts: new Date(data.ts),
  };
};

const loadCalculation = async (data: Forecast): Promise<CalculationExecNormalResult | null> => {
  const start = Date.now();

  let calculated = await backendStorage.getItem<Output | null>("economic_result", data.fact.projectId, data.id);

  if (calculated === null) {
    return null;
  }

  const delay = Date.now() - start;

  await when(() => data.ecyStore.isLoading === false);
  const uscModels = new Map(extractUSCModels(data).map(({ uuid, schema_usc }) => [uuid, { schema_usc }]));
  const resultByUSC = new Map(
    calculated!
      .filter(({ usc_uuid }) => uscModels.has(usc_uuid))
      .map((uscOutput) => [uscOutput.usc_uuid, wrapBackendUSCResult(uscModels, uscOutput)])
  );

  return {
    unchanged: [],
    changed: [],
    delay,
    calculated: resultByUSC,
  };
};

type CalculationVariant = "classic" | "infrastructure-total-only" | "infrastructure-by-well";

const postCalculation = async (
  data: Forecast,
  variant: CalculationVariant,
  isWithoutGtm: boolean
): Promise<CalculationExecNormalResult> => {
  replaceYearsRange(data.wholeRange);

  const request = provideRequest(data);
  const uscModels = new Map(request.usc_model.map(({ uuid, schema_usc }) => [uuid, { schema_usc }]));
  const { unchanged, changed } = unchangedPaths(request);
  const start = Date.now();

  const pipeSystem = variant !== "classic" ? await data.infrastructure.forSolverJSON(true) : undefined;
  const reqBody = objectToSnake({ inputData: request, pipeSystem });
  if (variant === "infrastructure-by-well") {
    (reqBody["pipe_system"] as any)["is_infrastructure_by_well"] = true;
  }
  reqBody.input_data.is_without_gtes = isWithoutGtm;
  const calculated = await req.post<Output>(`demo/schemas/new_calculation?scenario_id=${data.id}`, reqBody);
  if (calculated) {
    global.logger.addNote(`demo/schemas/new_calculation?scenario_id=${data.id}`);
  }

  backendStorage.setItem(calculated, "economic_result", data.fact.projectId, data.id);

  const resultByUSC = new Map(
    calculated.map((uscOutput) => [uscOutput.usc_uuid, wrapBackendUSCResult(uscModels, uscOutput)])
  );

  const cached: CachedValues = { isWithoutGtm };
  const fcRange = data.range;
  const ppgRange = new Range(fcRange.from + 1, fcRange.to);
  for (const [uscUuid, result] of resultByUSC) {
    cached[uscUuid] = {
      comparisonValues: extractComparisonValues(result.total, [data.wholeRange, ppgRange]),
    };
  }

  backendStorage.setItem(
    { ts: new Date(start), forecastId: data.id, cached },
    "economic_result_meta",
    data.fact.projectId,
    data.id
  );

  const delay = Date.now() - start;
  return {
    unchanged,
    changed,
    delay,
    calculated: resultByUSC,
  };
};

export type { CachedValues, CalculationExecNormalResult, CalculationVariant, Result, Schema };
export {
  adjustWellsSchema,
  loadCalculation,
  loadCalculationMeta,
  postCalculation,
  provideProperties,
  provideRequest,
  riseSchema,
  wrapBackendUSCResult,
};
