import { req } from "utils/request";

type CalculateSettings = {
  basisScenario: number;
  comparedScenario: number;
  basisUsc: number;
  comparedUsc: number;
};

type Factors = {
  basis: number;
  comparison: number;
  usc: number;
  production: number;
  opex: number;
  capex: number;
  other: number;
};

const FACTOR_NAMES: Record<keyof Factors, string> = {
  basis: "",
  comparison: "",
  usc: "Изменение ЕСУ",
  production: "Изменение добычи",
  opex: "Изменение OPEX",
  capex: "Изменение CAPEX",
  other: "Прочие факторы + НДД",
} as const;

async function calculate(settings: CalculateSettings): Promise<Factors> {
  const { basisScenario, comparedScenario, basisUsc, comparedUsc } = settings;
  const result = await req.post<Factors>(
    `factor-analysis/?base_scenario_id=${basisScenario}&compared_scenario_id=${comparedScenario}`,
    {
      usc_uuid_basis: basisUsc,
      usc_uuid_comp: comparedUsc,
    }
  );
  return result;
}

export type { CalculateSettings, Factors };
export { calculate, FACTOR_NAMES };
