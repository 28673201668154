import { FC } from "react";
import { Divider, Form, Input } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { MonthPicker } from "elements/inputs/monthPicker";
import { PipeType } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { CalculationEmpty } from "../../ui/calculationEmpty";
import { FieldParams } from "../../ui/fieldParams";

import { PipeLengthResetButton } from "./pipeLengthResetButton";

import cn from "./selectedItem.module.less";

type Props = { selectedItem: PipeType };

const TechnologyParams: FC<Props> = observer(({ selectedItem }) => {
  const { nodes, pipes, range } = useInfrastructure();
  const startRange = 1970;

  const [firstNode, secondNode] = [nodes.at(selectedItem.from), nodes.at(selectedItem.to)];
  return (
    <div className={cn.tehnologyWrapper}>
      <Form.Item name="startedAt" label="Дата ввода">
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => pipes.update({ uuid: selectedItem.uuid, startedAt: date })}
          start={dayjs(startRange.toString())}
          end={dayjs(range.to.toString())}
        />
      </Form.Item>
      <Form.Item label="Дата выбытия">
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => pipes.update({ uuid: selectedItem.uuid, finishedAt: date })}
          value={selectedItem.finishedAt}
          start={selectedItem.startedAt}
          end={dayjs(range.to.toString())}
        />
      </Form.Item>
      <div className={cn.paramsWrap}>
        <FieldParams className={cn.lengthWrap} title="Протяженность, м">
          <Input
            value={selectedItem.length ?? undefined}
            onChange={({ target }) => pipes.update({ uuid: selectedItem.uuid, length: target.value ? Number(target.value) : undefined })}
            placeholder="50"
            className={cn.input}
          />
          <PipeLengthResetButton selectedItem={selectedItem} />
        </FieldParams>
        <FieldParams title="Корректирующий коэф-т на трение">{selectedItem.frictionCorrectionFactor}</FieldParams>
      </div>
      {selectedItem.category !== null && (
        <div className={cn.paramsWrap}>
          <Divider className={cn.divider} />
          <FieldParams title="Внешний диметр">{selectedItem.diameterOuter} мм</FieldParams>
          <FieldParams title="Толщина стенки">{selectedItem.thickness} мм</FieldParams>
          <FieldParams title="Шероховатость стенки">{selectedItem.roughness} мм</FieldParams>
          <FieldParams title="Категория">{selectedItem.category}</FieldParams>
        </div>
      )}
      <div className={cn.paramsWrap}>
        <Divider className={cn.divider} />
        <FieldParams title="Узел начала">{firstNode?.title}</FieldParams>
        <FieldParams title="Координаты начала">
          X <Format>{firstNode?.x.toFixed(2)}</Format> Y <Format>{firstNode?.y.toFixed(2)}</Format> м
        </FieldParams>
        <FieldParams title="Альтитуда начала">{firstNode?.altitude} м</FieldParams>
      </div>
      <div className={cn.paramsWrap}>
        <Divider className={cn.divider} />
        <FieldParams title="Узел конца">{secondNode?.title}</FieldParams>
        <FieldParams title="Координаты конца">
          X <Format>{secondNode?.x.toFixed(2)}</Format> Y <Format>{secondNode?.y.toFixed(2)}</Format> м
        </FieldParams>
        <FieldParams title="Альтитуда конца">{secondNode?.altitude} м</FieldParams>
      </div>
      <CalculationEmpty type="hydraulic" />
    </div>
  );
});

export { TechnologyParams };
