import { FC } from "react";
import { FormOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { ForecastMode } from "services/back/techForecast/request";

import { useTechForecastModel } from "../useTechForecastModel";

type ApplyForAllGroupButtonProps = {
  group: "stopCriterion" | ForecastMode | "correction";
  className?: string;
};

const ApplyForGroupButton: FC<ApplyForAllGroupButtonProps> = ({ className, group }) => {
  const model = useTechForecastModel();

  if (model.selection.selectedEvents.length === 1) {
    return null;
  }

  const isValid = group === "correction" || model.currentForecast.settings[group].isValid;

  return (
    <Tooltip
      className={className}
      title={
        isValid
          ? group === "correction"
            ? "Применить выбор прогнозируемых параметров и настройки корректировки ко всей группе"
            : "Применить ко всей группе"
          : "Для применения настроек ко всей группе нужно закончить заполнение формы"
      }
    >
      <Button disabled={!isValid} onClick={model.onApplyForGroup(group)} type="text" icon={<FormOutlined />} />
    </Tooltip>
  );
};

export { ApplyForGroupButton };
