import type { FC } from "react";
import { Empty } from "antd";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";

import { useTechForecastModel } from "../../../useTechForecastModel";

import { Legend } from "./legend/legend";
import { ChartScene } from "./chartScene";

import cn from "./resultChart.module.less";

const ResultChart: FC = observer(() => {
  const model = useTechForecastModel();

  const { currentForecast } = model;
  const { channels } = currentForecast;

  if (channels === null) {
    if (currentForecast.settings.isValid === false) {
      return <Empty className={cn.empty} description="Для построения прогноза требуется закончить настройку параметров расчета" />;
    }
    return <Empty className={cn.empty} description="Не удалось выполнить запрос. Возможно при выполнении запроса возникли проблемы со связью" />;
  }
  return (
    <Loader wrapperClassName={cn.loader} tip={model.currentForecast.loaderTip} spinning={channels === undefined}>
      <div className={cn.layout} data-testid="result-chart">
        <ChartScene className={cn.scene} />
        <Legend chartModel={model.currentForecast.chart} />
      </div>
    </Loader>
  );
});

export { ResultChart };
