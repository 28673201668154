import { FC, useMemo } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { ChartContext } from "@okopok/axes_context";
import { Button } from "antd";
import { observer } from "mobx-react";

import { useScaleFromBars } from "elements/charts/stackedBars/useScaleFromBars";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { useBooleanState } from "utils/useBooleanState";

import { FactorAnalysisModel } from "../factorAnalysisModel";

import { FactorAnalysisBars } from "./factorAnalysisBars/factorAnalysisBars";
import { FactorAnalysisChartModel } from "./factorAnalysisChartModel";

import cn from "./factorAnalysisChart.module.less";

type FactorAnalysisChartProps = {
  model: FactorAnalysisModel;
};

const FactorAnalysisChart: FC<FactorAnalysisChartProps> = observer(({ model }) => {
  const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();

  const chartModel = useMemo(() => new FactorAnalysisChartModel(model), [model]);
  const axes = useScaleFromBars(chartModel.bars, chartModel.axisTitle, undefined, undefined, 1, 10, 20);
  if (chartModel.bars === null) {
    return <></>;
  }

  return (
    <div className={cn.layout}>
      <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
        <div className={cn.titleContainer}>
          <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
        </div>
        <div className={cn.wrap}>
          <ChartContext axes={axes} className={cn.chart}>
            <TooltipContext.Provider value={chartModel.tooltipManager}>
              <FactorAnalysisBars bars={chartModel.bars} />
            </TooltipContext.Provider>
          </ChartContext>
        </div>
      </FullScreen>
    </div>
  );
});

export { FactorAnalysisChart };
