import { useMemo } from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import "dayjs/locale/ru";

import { StationsModel } from "../../models/stations";

import cn from "./useParamStationsTableStore.module.less";

const useColumns = (): Column[] => {
  const { catalog, range } = useInfrastructure();
  return useMemo(
    (): Column[] => [
      {
        title: "Название",
        width: { min: 154, max: 200, competitiveness: 1 },
        dataKey: "title",
        isSticky: true,
        type: "string",
        editable: true,
        onCell: () => ({ className: classNames(cn.tableFirstCell, cn.tableCell) }),
      },
      {
        title: "Вид",
        width: { min: 154, max: 200, competitiveness: 1 },
        dataKey: "kind",
        type: "select",
        options: catalog.stationsSelector,
        editable: true,
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Тип",
        width: { min: 70, max: 80, competitiveness: 1 },
        dataKey: "stationType",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Категория",
        width: { min: 132, max: 150, competitiveness: 1 },
        dataKey: "category",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Мощность, тыс м3/сут",
        width: { min: 140, max: 150, competitiveness: 1 },
        dataKey: "power",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Перепад давления, атм",
        width: { min: 180, max: 180, competitiveness: 1 },
        dataKey: "deltaPressure",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Дата ввода",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "startedAt",
        type: "date",
        renderFormat: "month",
        start: dayjs().year(range.from),
        end: dayjs().year(range.to),
        editable: true,
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
      },
      {
        title: "Дата выбытия",
        width: { min: 150, max: 150, competitiveness: 1 },
        dataKey: "finishedAt",
        type: "date",
        renderFormat: "month",
        start: (tableItem: any) => dayjs(tableItem.value.startedAt),
        end: dayjs().year(range.to),
        editable: true,
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
      },
      {
        title: "Связанные участки",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "relatedPipes",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Координаты, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        dataKey: "coords",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Альтитуда, м",
        width: { min: 140, max: 150, competitiveness: 1 },
        dataKey: "altitude",
        type: "number",
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Стоимость строительства, тыс руб / (тыс м3/сут)",
        width: { min: 250, max: 270, competitiveness: 1 },
        dataKey: "cost",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Учет реконструкции",
        width: { min: 150, max: 150, competitiveness: 1 },
        dataKey: "isReconstruction",
        editable: true,
        type: "set",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Стоимость реконструкции, тыс руб / (тыс м3/сут)",
        width: { min: 250, max: 270, competitiveness: 1 },
        dataKey: "costReconstruction",
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
    ],
    [catalog, range]
  );
};

const useParamStationsTableStore = () => {
  const infrastructure = useInfrastructure();
  const columns = useColumns();
  const store = useMemo(() => new StationsModel(infrastructure), [infrastructure]);
  return { columns, store };
};

export { useParamStationsTableStore };
