import { action, makeObservable, observable, ObservableMap, ObservableSet } from "mobx";
import { makePersistable } from "mobx-persist-store";

class LegendSettings {
  public colors: ObservableMap<string, string> = observable.map();
  public hidden: ObservableSet<string> = observable.set();
  public hideUngrouped = false;

  constructor() {
    makeObservable(this, {
      colors: observable,
      hidden: observable,
      hideUngrouped: observable,
      setColors: action,
      setHidden: action,
      setHideUngrouped: action,
    });

    makePersistable<LegendSettings, "hidden" | "colors" | "hideUngrouped">(this, {
      name: `tech-forecast-chart-channels`,
      properties: [
        {
          key: "hidden",
          serialize: (value) => JSON.stringify([...value.values()]),
          deserialize: (value) => observable.set<string>(JSON.parse(value)),
        },
        {
          key: "colors",
          serialize: (value) => JSON.stringify([...value.entries()]),
          deserialize: (value) => observable.map<string, string>(JSON.parse(value)),
        },
        "hideUngrouped",
      ],
      storage: window.localStorage,
    });
  }

  public setColors = (colors: ObservableMap<string, string>) => {
    this.colors.replace(colors);
  };
  public setHidden = (hidden: ObservableSet<string>) => {
    this.hidden.replace(hidden);
  };
  public setHideUngrouped = (hide: boolean) => {
    this.hideUngrouped = hide;
  };
}

export { LegendSettings };
