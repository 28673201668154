import { useMemo } from "react";

import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { useProject } from "models/project/project";

import { FactorAnalysisChart } from "./chart/factorAnalysisChart";
import { FactorAnalysisSettings } from "./settings/settings";
import { FactorAnalysisTable } from "./table/table";
import { FactorAnalysisModel } from "./factorAnalysisModel";

import cn from "./factorAnalysis.module.less";

const FactorAnalysis = () => {
  const project = useProject()!;
  const storage = useSearchParamsStorage();
  const model = useMemo(() => new FactorAnalysisModel(project, storage), [project, storage]);

  return (
    <div className={cn.main}>
      <div className={cn.sectionTop}>
        <div className={cn.settings}>
          <FactorAnalysisSettings model={model} />
        </div>
        <div className={cn.table}>
          <FactorAnalysisTable model={model} />
        </div>
      </div>
      <div className={cn.chart}>
        <FactorAnalysisChart model={model} />
      </div>
    </div>
  );
};

export { FactorAnalysis };
