import { RadioChangeEvent } from "antd";
import {
  action,
  computed,
  type IObservableArray,
  makeObservable,
  observable,
  reaction,
  runInAction,
  transaction,
  when,
} from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import {
  type CostDump,
  type CostParamTable,
  type CostsDescription as RawCostParams,
  getPerUnit,
  getTotal,
  TotalDump,
  type ValueDump,
} from "services/back/costs";
import { conditionally } from "utils/conditionally";

class OptionedParam {
  public readonly id: number;
  public readonly title: string;
  public readonly group: string | null;
  public readonly primaryUOM: UOM;
  public readonly secondaryUOM: UOM | null;
  public readonly isOperations: boolean;
  public readonly isNet: boolean;

  public primary: null | number = null;
  public secondary: null | number = null;
  public vector: IObservableArray<number | null>;
  public get values() {
    return this.vector;
  }
  public inputMethod: ValueDump["inputMethod"];

  public autoCalc: undefined | number | null = null;
  public table: undefined | null | CostParamTable;

  public setPrimary = (v: number | null) =>
    transaction(() => {
      this.primary = v;
      this.inputMethod = "primary";
    });
  public setSecondary = (v: number | null) => {
    transaction(() => {
      this.secondary = v;
      this.inputMethod = "secondary";
    });
  };
  public toAutoCalc = () => this.setPrimary(this.autoCalc ?? null);
  public setVectorValue = (v: number | null, yearId: number) => {
    transaction(() => {
      this.vector[yearId] = v;
      this.table = undefined;
      this.inputMethod = "secondary";
    });
    this.loadTable();
  };
  public get setValue() {
    return this.setVectorValue;
  }
  public setInputMethod = (e: RadioChangeEvent) => {
    if (e.target.value === this.inputMethod) {
      return;
    }
    this.inputMethod = e.target.value ?? this.inputMethod;
    this.table = undefined;
    this.loadTable();
  };
  public fillNetVector = async () => {
    await when(() => this.table !== undefined);
    this.vector.replace(this.table!.net);
  };

  constructor({ value, description }: CostDump, public forecast: Forecast) {
    this.id = description.id;
    this.title = description.title;
    this.group = description.group;
    this.primaryUOM = UOM.fromObject(description.primary, global.uomResolver);
    this.secondaryUOM =
      description.secondary !== null ? UOM.fromObject(description.secondary, global.uomResolver) : null;
    this.isOperations = description.is_operations;
    this.isNet = description.is_net;
    this.vector = observable.array(value?.vector ?? this.range.array.fill(this.isOperations ? 0 : null));
    this.primary = value?.primary ?? null;
    this.secondary = value?.secondary ?? null;
    this.inputMethod = value?.inputMethod ?? (this?.secondary ? "secondary" : "primary");

    makeObservable(this, {
      isCompleted: computed,
      primary: observable,
      secondary: observable,
      autoCalc: observable,
      table: observable,
      inputMethod: observable,
      fillNetVector: action,
      setPrimary: action,
      setSecondary: action,
      toAutoCalc: action,
      setVectorValue: action,
      setInputMethod: action,
    });

    reaction(
      () => [this.primary, this.secondary],
      () => {
        runInAction(() => {
          this.table = undefined;
        });
        this.loadTable();
      },
      { fireImmediately: this.isCompleted }
    );
  }

  get descriptionDump(): RawCostParams {
    return {
      id: this.id,
      title: this.title,
      group: this.group,
      primary: this.primaryUOM.dump,
      secondary: this.secondaryUOM?.dump ?? null,
      is_operations: this.isOperations,
      is_net: this.isNet,
    };
  }

  get valuesDump(): ValueDump {
    return {
      primary: this.primary,
      secondary: this.secondary,
      vector: this.vector,
    };
  }

  get dump(): CostDump {
    return {
      value: this.valuesDump,
      description: this.descriptionDump,
    };
  }

  public get totalDump(): TotalDump {
    const isSecondary = this.inputMethod === "secondary";
    if (!this.isNet && !this.isOperations) {
      return {
        id: this.id,
        ...conditionally(!isSecondary, { primary: this.primary }),
        ...conditionally(isSecondary, { secondary: this.secondary }),
      };
    }
    if (this.isOperations) {
      const isPerYear = isSecondary && this.secondary !== null && !this.vector.includes(null);
      return {
        id: this.id,
        ...conditionally(!isPerYear, { primary: this.primary }),
        ...conditionally(isPerYear, { secondary: this.secondary }),
        ...conditionally(isPerYear, { operations: this.vector }),
      };
    }
    //net
    return {
      id: this.id,
      ...conditionally(!isSecondary, { primary: this.primary }),
      ...conditionally(isSecondary, { net: this.vector }),
    };
  }

  public async prepareAutoCalc() {
    runInAction(() => {
      this.autoCalc = undefined;
    });
    const autoCalc = await this.getFact();
    runInAction(() => {
      transaction(() => {
        this.autoCalc = autoCalc;
        if (!this.isCompleted) {
          this.primary = autoCalc;
          this.secondary = null;
        }
      });
    });
  }

  private async getFact(): Promise<number> {
    await this.forecast.calculatePreload();
    return getPerUnit(this.forecast, this.id);
  }

  public async loadTable(): Promise<void> {
    try {
      const table = await this.getTable();
      runInAction(() => {
        transaction(() => {
          this.table = table;
          if (this.isNet) {
            this.vector.replace(this.vector.map((v, id) => v ?? table.net[id]));
          }
        });
      });
    } catch {
      this.table = null;
    }
  }

  private async getTable(): Promise<CostParamTable> {
    await this.forecast.calculatePreload();
    return getTotal(this.forecast, this.totalDump);
  }

  get range() {
    return this.forecast.range;
  }

  get isCompleted() {
    if (this.inputMethod === "primary") {
      return this.primary !== null;
    }
    return (
      (this.secondary !== null || this.isNet) && (!this.vector.includes(null) || (!this.isOperations && !this.isNet))
    );
  }
}

export { OptionedParam };
