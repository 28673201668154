import { FC, ReactNode } from "react";
import { Button, ConfigProvider, Divider, Popover, Select, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { Attention } from "elements/icons/attention";
import { Reset } from "elements/icons/reset";
import { LinkButton } from "elements/linkButton/linkButton";
import { useProject } from "models/project/project";

import { FactorAnalysisModel } from "../factorAnalysisModel";

import { FactorAnalysisSettingsModel } from "./model";

import cn from "./settings.module.less";

type SelectorProps = {
  settings: FactorAnalysisSettingsModel;
  name: keyof FactorAnalysisSettingsModel["settings"];
  label: string;
};

const VALIDATION_ERROR: Record<string, (...args: any[]) => { title: string; content: ReactNode }> = {
  recalculate: (projectId: number, scenarioId: number) => ({
    title: "Необходим пересчет",
    content: (
      <div className={cn.recalculateErrorContent}>
        <span>Сценарий имеет устаревшие результаты экономического расчета</span>
        <LinkButton to={`/${projectId}/${scenarioId}/money`} type="link" className={cn.link}>
          Перейти к расчету
        </LinkButton>
      </div>
    ),
  }),
};

const Selector: FC<SelectorProps> = observer(({ settings, name, label }) => {
  const projectId = useProject()!.id;
  const item = settings.getItem(name);

  return (
    <div className={cn.settingsItemRow}>
      <div className={cn.settingsItem}>
        <Typography.Title level={3}>{label}</Typography.Title>
        <Select
          value={item.value}
          options={item.options}
          disabled={item.disabled}
          className={cn.settingsSelect}
          onSelect={(value) => settings.setItem(name, value)}
          status={item.error && "error"}
        />
      </div>
      {item.error ? (
        <Popover {...VALIDATION_ERROR[item.error](projectId, item.value)}>
          <div className={cn.warnIconWrapper}>
            <Attention />
          </div>
        </Popover>
      ) : null}
    </div>
  );
});

const FactorAnalysisSettings = observer(({ model }: { model: FactorAnalysisModel }) => {
  const { settings, calculate } = model;
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            titleMarginBottom: 8,
          },
          Divider: {
            marginLG: 2,
          },
        },
      }}
    >
      <div className={cn.settingsContainer}>
        <div className={cn.settingsToolbar}>
          <Button type="text" disabled={!settings.canReset} icon={<Reset />} onClick={settings.clear} />
          <Button type="primary" loading={model.factors === undefined} disabled={!settings.isValid} onClick={calculate}>
            Провести ФА
          </Button>
        </div>
        <Divider />
        <Typography.Title level={2}>Выбрать сценарии для проведения ФА</Typography.Title>
        <Divider />
        <Selector settings={settings} label="Базисный сценарий" name="basisScenario" />
        <Selector settings={settings} label="Сравниваемый сценарий" name="comparedScenario" />
        <Divider />
        <Typography.Title level={2}>Выбрать варианты ЕСУ для проведения ФА</Typography.Title>
        <Divider />
        <Selector settings={settings} label="ЕСУ, в которой проводится сравнение" name="basisUsc" />
        <Selector settings={settings} label="ЕСУ - фактор" name="comparedUsc" />
      </div>
    </ConfigProvider>
  );
});

export { FactorAnalysisSettings };
