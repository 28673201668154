import { NUMBER_FORMAT } from "elements/format/format";
import { ComparisonValues, extractComparisonValues } from "features/comparison/compareScenario/utils/extractValues";
import type { ParamsRow as FinancialParamsRow } from "features/useMetrics/paramsRow";
import { global } from "models/global";
import { ParamsNode } from "models/params/paramsNode";
import { Table } from "models/table";
import type { CalculationExecNormalResult, Result, Schema } from "services/back/calculate/calculate";
import { WELLS_KEY_STEP } from "services/back/calculate/calculate";
import { Metric, MetricTree } from "services/finance/utils";
import { Range } from "utils/range";
import { numerateTree, ofTree } from "utils/tree";

import { Forecast } from "./forecast";

class ResultsTable extends Table {
  protected readonly metrics: ParamsNode[];

  public get years(): Range {
    return global.isPickingFactYears ? this.forecast.wholeRange : this.forecast.range;
  }

  constructor(private readonly forecast: Forecast, schema: Array<MetricTree>) {
    super("");
    console.assert(JSON.stringify(schema[0].values) === JSON.stringify([...forecast.wholeRange]), "Первое поле не год");
    this.metrics = schema.slice(1).map(ParamsNode.solutionFromRaw);
    this.metrics.forEach((v) => v.propagateParent());
  }

  get isUpdated(): boolean {
    return false;
  }

  get isLoading(): boolean {
    return false;
  }

  get isCompleted(): boolean {
    return true;
  }

  export(): (string | null)[][] {
    const toString = (v: null | undefined | boolean | string | number): string | null => {
      if (v === null || v === undefined) {
        return null;
      }
      if (typeof v === "boolean") {
        return v ? "да" : "нет";
      }
      if (typeof v === "number") {
        return NUMBER_FORMAT.locale_C.format(v);
      }
      return v;
    };

    const exportRows = (rows: FinancialParamsRow[]): (string | null)[][] => {
      return rows.flatMap((row) => {
        const baseRow = [row.title, row.measure, ...(row.values?.map(toString) ?? [])];
        if (row.children && row.children.length > 0) {
          return [baseRow, ...exportRows(row.children)];
        }

        return [baseRow];
      });
    };

    return [["Параметр", "Единицы измерения", ...[...this.years].map((v) => `${v}`)], ...exportRows(this.tableItems)];
  }

  get tableItems(): FinancialParamsRow[] {
    const result = this.metrics.map((v) => v.tableItems);
    for (const metric of ofTree(result)) {
      metric.values = metric.values?.slice(this.years.from - this.forecast.factRange.from);
    }
    numerateTree(result);
    return result;
  }
}

class RelativeResultsTable extends ResultsTable {
  private longest;

  private makeRelativeYears() {
    // Поскважинное выравнивание
    const wells = this.metrics.filter(({ key }) => key >= WELLS_KEY_STEP);
    for (const well of wells) {
      let start = 1e20;
      // находим самое раннее упоминание данных
      for (const metric of ofTree(well.children!)) {
        if (metric.personalValues && metric.personalValues.includes(null)) {
          start = Math.min(
            start,
            metric.personalValues.findIndex((v) => v !== null)
          );
        }
      }
      // обрезаем всё что до и запоминаем длину
      for (const metric of ofTree(well.children!)) {
        if (metric.personalValues && metric.personalValues.includes(null)) {
          metric.personalValues = metric.personalValues.slice(start);
          this.longest = Math.max(
            this.longest,
            (metric.personalValues as any).findLastIndex((v: number | null) => v !== null)
          );
        }
      }
    }
    // дополняем по длине налами
    for (const metric of ofTree(this.metrics)) {
      if (metric.personalValues) {
        metric.personalValues = [...metric.personalValues, ...new Array(this.longest).fill(null)].slice(
          0,
          this.longest
        );
      }
    }
  }

  constructor(forecast: Forecast, schema: Array<MetricTree>) {
    super(forecast, schema);
    this.longest = 0;
  }

  public get years(): Range {
    return super.years;
  }
}

type WellOptimizationTableItem = {
  NPV: number;
  PI: number;
  key: number | string;
  name: string;
  id: number;
};

class WellsResultTable extends RelativeResultsTable {
  get tableItems(): FinancialParamsRow[] {
    const result = this.metrics.map((v) => v.tableItems);
    // NOTE: no slice by picked years
    numerateTree(result);
    return result;
  }

  get metricBase(): WellOptimizationTableItem[] {
    return this.tableItems
      .filter(({ children }) => children)
      .map((well: any) => ({
        id: well.id,
        key: well.row,
        name: well.title,
        NPV: well.children
          .find(({ title }: any) => title === "NPV")
          .values.filter((v: any) => v !== null)
          .slice(-1)[0],
        PI: well.children
          .find(({ title }: any) => title === "PI")
          .values.filter((v: any) => v !== null)
          .slice(-1)[0],
      }));
  }
}

type ForecastResultProps = Omit<CalculationExecNormalResult, "calculated"> & {
  calculated: Result;
};

class ForecastResult {
  delay: number;
  result: Result;
  changed: string[];
  unchanged: string[];
  selectedLicenseRegion?: string;

  invest: ResultsTable;
  operating: ResultsTable;
  cashflow: ResultsTable;
  wells: WellsResultTable;

  mainResults: Record<"NPV 1" | "PBP 1" | "dPBP 1" | "IRR 1" | "NPV 2" | "PBP 2" | "dPBP 2" | "IRR 2", number>;
  comparisonValues: ComparisonValues;

  constructor(
    public readonly forecast: Forecast,
    { delay, calculated: result, changed, unchanged }: ForecastResultProps,
    selectedLicenseRegion?: string
  ) {
    this.delay = delay;
    this.result = result;
    this.changed = changed;
    this.unchanged = unchanged;
    this.selectedLicenseRegion = selectedLicenseRegion;
    this.invest = new ResultsTable(forecast, result.schemaInvest);
    this.operating = new ResultsTable(forecast, result.schemaOperation);
    this.cashflow = new ResultsTable(forecast, result.schemaCashflow);
    this.wells = new WellsResultTable(forecast, result.schemaWells);
    this.mainResults = Object.fromEntries(
      result.result.schema.map(({ title, values }) => [title, values?.[0] ?? 0])
    ) as any;

    const fcRange = this.forecast.range;
    const ppgRange = new Range(fcRange.from + 1, fcRange.to);
    this.comparisonValues = extractComparisonValues(this.result, [this.forecast.wholeRange, ppgRange]);
  }

  getMetricByTitle(title: string): Metric | null {
    for (const schemaName of Object.keys(this.result).filter((key) => key.startsWith("schema_") || key === "result")) {
      const metric = (this.result[schemaName as keyof Result] as Schema).schema?.find((item) => item.title === title);
      if (metric) {
        return metric;
      }
    }
    return null;
  }
}

export { ForecastResult, ResultsTable, type WellOptimizationTableItem, WellsResultTable };
