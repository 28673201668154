import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react";
import { ReactComponent as SaveIcon } from "routing/outlines/submitButton/notSaveIcon.svg";

import { Icon } from "elements/icon/icon";
import { useModal } from "elements/modal/modal";
import { TypeForecastSettings } from "features/techForecast/models/typeForecastSettings";
import { Methods } from "features/techForecast/models/well/methods";
import { ForecastMode } from "services/back/techForecast/request";

import { SaveTypicalModal } from "./saveTypicalModal";

type SaveTypicalButtonProps = {
  typicalSettings: TypeForecastSettings;
  modeSettings: Methods;
  mode: ForecastMode;
  className?: string;
  loading?: boolean;
  disabledTooltip?: string;
};

const useSaveTypicalModal = (settings: TypeForecastSettings, modeSettings: Methods, mode: ForecastMode) => {
  const showModal = useModal<void, { settings: TypeForecastSettings; modeSettings: Methods; mode: ForecastMode }>();
  const dataRef = useRef({ settings, modeSettings, mode });
  dataRef.current.settings = settings;
  dataRef.current.modeSettings = modeSettings;
  dataRef.current.mode = mode;

  return useCallback(() => showModal(SaveTypicalModal, dataRef, "Сохранение типовой кривой", "Сохранить", "Отмена"), [showModal]);
};

const SaveTypicalButton: FC<SaveTypicalButtonProps> = observer(({ disabledTooltip, typicalSettings, modeSettings, mode, className, loading }) => {
  const onSaveClick = useSaveTypicalModal(typicalSettings, modeSettings, mode);

  const [hasTypical, setHasTypical] = useState(false);
  const [typicalName, setTypicalName] = useState<string | null>(null);

  const actuallyTypical = modeSettings.asTypical;
  const tooltipTitle = hasTypical
    ? `Текущие настройки уже сохранены под именем "${typicalName}"`
    : disabledTooltip
    ? disabledTooltip
    : "Сохранить типовую кривую";

  useEffect(() => {
    const checkHasTypical = () => {
      if (!typicalSettings.data || !actuallyTypical) return;

      const foundTypical =
        typicalSettings.data?.find((item) => {
          const { uuid, title, ...rest } = item;
          const { ...typicalRest } = actuallyTypical;

          const typicalKeys = Object.keys(typicalRest) as Array<keyof typeof typicalRest>;

          return typicalKeys.every((key) => rest[key] === typicalRest[key]);
        }) ?? false;

      setHasTypical(!!foundTypical);
      foundTypical && setTypicalName(foundTypical.title);
    };

    checkHasTypical();
  }, [actuallyTypical, typicalSettings]);

  return (
    <Tooltip className={className} title={tooltipTitle}>
      <Button
        data-testid="save_btn"
        loading={loading}
        type="text"
        icon={<Icon style={{ stroke: "none" }} content={<SaveIcon />} width="20" height="20" viewBox={"0 0 16 18"} />}
        onClick={onSaveClick}
        disabled={hasTypical || typeof disabledTooltip === "string"}
      />
    </Tooltip>
  );
});

export { SaveTypicalButton };
