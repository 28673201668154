import dayjs, { Dayjs } from "dayjs";
import { action, makeObservable, observable } from "mobx";

import { global } from "models/global";
import { BackendStorageMock } from "services/back/backendStorage";
import {
  calculateInfrastructure,
  calculateMultipleInfrastructures,
  calculatePipeCost,
  ResponsePipeCost,
  SimulationInput,
  SimulationOutput,
} from "services/back/infrastructure/calculate";
import { PipeBoundaryCondition } from "services/back/infrastructure/types";
import { Metric } from "services/finance/utils";

import { Infrastructure } from "./infrastructure";

type UserFilterParams = { date?: [Dayjs, Dayjs] | null; pressureGradient?: number | null; velocity?: number | null };

type InfrastructureDynamicCalculationDump = {
  data: {
    ts: Date;
    values:
      | ({
          date: Dayjs;
          drainSources: PipeBoundaryCondition[];
        } & SimulationOutput)[]
      | null;
  };
};

const InfrastructureDynamicCalculationSource = (key: { fact: number; forecast?: number }) => {
  return new BackendStorageMock<void, InfrastructureDynamicCalculationDump>(
    `InfrastructureDynamicCalculationSource_${key.fact}_${key.forecast}`,
    key.fact,
    key.forecast
  );
};

class CalculateStore {
  hydraulicData?: SimulationOutput | null;
  hydraulicDataCollection: ({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationOutput)[] | null = null;
  economicData: {
    mines: ResponsePipeCost["mines"]["res"];
    segments: ResponsePipeCost["segments"]["res"];
    stations: ResponsePipeCost["stations"]["res"];
    schema: Metric[];
  } | null = null;
  isCalculation: boolean = false;
  lastTS: Date | undefined | null = undefined;
  error?: string | null;

  userFilterParams: UserFilterParams | null = null;
  source:
    | BackendStorageMock<void, InfrastructureDynamicCalculationDump, InfrastructureDynamicCalculationDump>
    | undefined;
  constructor(private parent: Infrastructure) {
    makeObservable<CalculateStore, "init">(this, {
      hydraulicData: observable,
      hydraulicDataCollection: observable,
      economicData: observable,
      lastTS: observable,
      calculateHydraulic: action,
      calculateEconomic: action,
      clearCalculation: action,
      init: action,
      isCalculation: observable,
      setUserFilterParams: action,
      userFilterParams: observable,
      resetUserFiterDate: action,
      resetUserFiterInputs: action,
    });
    this.source =
      this.parent.forecast !== null
        ? InfrastructureDynamicCalculationSource(this.parent.forecast.storageKey)
        : undefined;

    this.init();
  }

  private async init() {
    const data = await this.source?.getItem();
    if (data === null) {
      this.lastTS = null;
    } else {
      this.lastTS = data?.data.ts;
    }
  }

  public calculateHydraulic = async (isMulti?: boolean, period?: [Dayjs, Dayjs]) => {
    const { from: startYear, to: endYear } = this.parent.range;
    const lastFactDate = global.lastFactDate ?? dayjs().year(startYear).startOf("year");
    const [startDate, endDate] = period ?? [lastFactDate.add(1, "month"), dayjs().year(endYear).endOf("year")];

    try {
      this.isCalculation = true;
      if (isMulti) {
        const result: ({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationInput)[] = [];
        let currentDate = startDate;
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
          const pipeSystemJSON = await this.parent.forSolverJSON(true, currentDate);
          result.push({ date: currentDate, ...pipeSystemJSON });
          currentDate = currentDate.add(1, "month");
        }
        const response = await calculateMultipleInfrastructures(result);
        this.hydraulicDataCollection = response;
        if (this.source) {
          const ts = new Date();
          await this.source.setItem({
            data: {
              ts: new Date(),
              values: response,
            },
          });
          this.lastTS = ts;
        }
        this.isCalculation = false;
      } else {
        const pipeSystemJSON = await this.parent.forSolverJSON();
        const result = await calculateInfrastructure(pipeSystemJSON);
        this.hydraulicData = result;
        this.isCalculation = false;
      }
    } catch (error) {
      console.error("Error during hydraulic calculation", error);
    } finally {
      this.isCalculation = false;
    }
  };

  public calculateEconomic = async (ecyId?: number) => {
    try {
      const constructionPriceIndex = ecyId ? this.parent.indexPriceEcyMap.get(ecyId) : undefined;
      this.isCalculation = true;
      const pipeSystemJSON = await this.parent.forSolverJSON(true);
      const { mines, segments, stations, schema } = await calculatePipeCost(
        {
          ...pipeSystemJSON,
          constructionPriceIndex,
          scenarioId: this.parent.forecast?.id!,
        },
        this.parent.forecast?.range!
      );
      this.economicData = { mines: mines.res, segments: segments.res, stations: stations.res, schema };
      this.isCalculation = false;
    } catch (error) {
      console.error("Error during economic calculation", error);
    } finally {
      this.isCalculation = false;
    }
  };

  setUserFilterParams = (params: UserFilterParams) => {
    this.userFilterParams = { ...this.userFilterParams, ...params };
  };

  resetUserFiterDate = () => {
    if (!this.userFilterParams) return;
    this.userFilterParams.date = null;
  };
  resetUserFiterInputs = () => {
    if (!this.userFilterParams) return;
    this.userFilterParams.pressureGradient = null;
    this.userFilterParams.velocity = null;
  };

  clearCalculation = () => {
    this.hydraulicData = null;
  };
}

export { CalculateStore };
export type { InfrastructureDynamicCalculationDump };
