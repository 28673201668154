import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { reaction } from "mobx";

import { FactorAnalysisModel, type FactorOrder } from "../factorAnalysisModel";
import { FACTOR_NAMES } from "../services";

type DRow = {
  scenario: number | null;
  usc: number;
  factor: string;
  npv: number;
  npvDelta: number | null;
  npvDeltaRelative: number | null;
};

class FactorRow extends TableNode<DRow> {
  asDRow(): DRow {
    const { name, scenario, usc, npv, npvDelta = null, npvDeltaRelative = null } = this.factor!;
    const shouldIncludeScenario = ["", FACTOR_NAMES["usc"]].includes(name);

    return {
      scenario: shouldIncludeScenario ? scenario : null,
      usc,
      factor: name,
      npv,
      npvDelta,
      npvDeltaRelative,
    };
  }

  constructor(
    public readonly parent: FactorAnalysisTableModel,
    public readonly order: FactorOrder,
    public readonly rowIndex: number
  ) {
    super(parent);
    this.childrenStore = null;
  }

  public get factor() {
    return this.order.getFactor(this.rowIndex);
  }
}

class FactorAnalysisTableModel extends TableNode<DRow, FactorRow> {
  constructor(public readonly model: FactorAnalysisModel) {
    super();
    reaction(
      () => model.factors,
      (order) => order && this.init(order),
      { fireImmediately: true }
    );
  }

  private init(order: FactorOrder) {
    this.childrenStore = new ChildrenStoreArray(
      this,
      Array(order.length)
        .fill(null)
        .map((_, i) => new FactorRow(this, order, i))
    );
  }
}

export type { DRow };
export { FactorAnalysisTableModel };
