import { FC, ReactNode } from "react";
import { Tooltip } from "antd";

import { Icon } from "elements/icon/icon";
import { PipeType, useInfrastructureMapContext } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import theme from "theme/global";

import { Card } from "../../ui/card";
import { Parameter } from "../../ui/parameter";

import { ReactComponent as InnerDiameterIcon } from "./innerDiameter.svg";
import { ReactComponent as LimitFlowRateIcon } from "./limitFlowRate.svg";
import { ReactComponent as LimitPressureGradientIcon } from "./limitPressureGradient.svg";
import { ReactComponent as SteelIcon } from "./steel.svg";

import cn from "./pipeCatalog.module.less";

const pipeParams = {
  diameterOuter: "Внешний диаметр",
  thickness: "Толщина стенки",
  limitingVelocity: "Пред. скорость потока",
  limitingPressureGradient: "Пред. градиент давления",
  roughness: "Шероховатость стенки",
  material: "Материальное исполнение",
  unitCost: "Удельная стоимость",
} as const;

type PipeParams = {
  onClick?: () => void;
  title: string;
  diameterOuter: number;
  thickness: number;
  material: string;
  limitingVelocity: string;
  limitingPressureGradient: string;
  roughness: string;
  unitCost: string;
};

const TooltipPipeCard: FC<{ selectedItem: Omit<PipeParams, "title" | "onClick">; children: ReactNode }> = ({ selectedItem, children }) => {
  const params = Object.entries(selectedItem).map(([key, value]) => ({ key, value })) as { key: keyof typeof pipeParams; value: string }[];
  return (
    <Tooltip
      overlayStyle={{ maxWidth: "330px", borderRadius: "8px" }}
      overlayInnerStyle={{ backgroundColor: theme["icon-color"] }}
      title={
        <div className={cn.tooltip}>
          {params.map(({ key, value }) => (
            <Parameter key={key} title={pipeParams[key]}>
              {value}
            </Parameter>
          ))}
        </div>
      }
      placement="left"
    >
      {children}
    </Tooltip>
  );
};

const PipeCard: FC<PipeParams> = ({ onClick, title, ...pipe }) => {
  return (
    <Card title={title} className={cn.pipeCard} onClick={onClick}>
      <TooltipPipeCard selectedItem={pipe}>
        <div className={cn.pipeParamsWrap}>
          <Parameter
            icon={<Icon content={<InnerDiameterIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}
          >{`${pipe.diameterOuter} x ${pipe.thickness} мм`}</Parameter>
          <Parameter icon={<Icon content={<LimitFlowRateIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}>
            {pipe.limitingVelocity}
          </Parameter>
          <Parameter icon={<Icon content={<LimitPressureGradientIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}>
            {pipe.limitingPressureGradient}
          </Parameter>
          <Parameter icon={<Icon content={<SteelIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}>{pipe.material}</Parameter>
        </div>
      </TooltipPipeCard>
    </Card>
  );
};

const PipeCatalog: FC<{ selectedItem: PipeType; onClose?: () => void }> = ({ selectedItem, onClose }) => {
  const { updatePipe, infrastructure } = useInfrastructureMapContext();

  const handleClick = (id: string, segmentType: PipeType["segmentType"], catalogItem: Partial<PipeType> | null) => {
    updatePipe(id, { ...catalogItem, segmentType, category: "Новый объект", title: selectedItem.title });
    onClose?.();
  };

  return (
    <div className={cn.catalogWrap}>
      <div className={cn.categoryList}>
        {(infrastructure.catalog.pipes(selectedItem.segmentType) || []).map((pipe) => (
          <PipeCard
            key={pipe.uuid}
            title={pipe.title}
            material={pipe.material ?? "Сталь"}
            diameterOuter={pipe.diameterOuter}
            thickness={pipe.thickness}
            limitingVelocity={`${pipe.limitingVelocity} м/сек`}
            limitingPressureGradient={`${pipe.limitingPressureGradient?.toFixed(6)} атм/м`}
            roughness={`${pipe.roughness} мм`}
            unitCost={`${pipe.roughness} тыс руб / км`}
            onClick={() => handleClick(selectedItem.uuid, selectedItem.segmentType, pipe as Partial<PipeType>)}
          />
        ))}
      </div>
    </div>
  );
};

export { PipeCard, PipeCatalog };
