import { req } from "utils/request";

type WellYearStatusBackend = {
  year: number;
  start_time: string; // date
  stratums: Array<{
    stratum_id: number;
    stratum_title: string;
    spr_id: number;
    status_id: number;
    status_title: string;
    state_type_id: number;
    state_type_title: string;
  }>;
};

type WellStatusBackend = {
  well_id: number;
  well_title: string;
  years: WellYearStatusBackend[];
};

//                             well_id        year
type WellsFondsByYear = Record<number, Record<number, { prod: boolean; inj: boolean }>>;

async function getBaseWellsStatus(scenarioId: number): Promise<WellsFondsByYear> {
  const raw = await req.get<WellStatusBackend[]>(`/productions/status-by-well/${scenarioId}`);
  return calculateFonds(raw);
}

const PROD_STATE_TYPE_TITLE = "Нефтяная" as const;
const PROD_STATUS_TITLES = ["В периодической эксплуатации", "В работе", "Остановлена"];

const INJ_STATE_TYPE_TITLE = "Нагнетательная" as const;
const INJ_STATUS_TITLES = ["В периодической эксплуатации", "В работе", "Остановлена"];

function calculateFonds(raw: WellStatusBackend[]): WellsFondsByYear {
  const result: WellsFondsByYear = {};
  for (const well of raw) {
    const accum: WellsFondsByYear[number] = {};
    for (const { year, stratums } of well.years) {
      const current = { prod: false, inj: false };
      for (const { status_title, state_type_title } of stratums) {
        if (state_type_title === PROD_STATE_TYPE_TITLE && PROD_STATUS_TITLES.includes(status_title)) {
          current.prod = true;
        }
        if (state_type_title === INJ_STATE_TYPE_TITLE && INJ_STATUS_TITLES.includes(status_title)) {
          current.inj = true;
        }
      }
      accum[year] = current;
    }
    result[well.well_id] = accum;
  }
  return result;
}

export type { WellsFondsByYear };
export { getBaseWellsStatus };
