import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { StationType } from "services/back/infrastructure/catalog";
import { PipeSystem, Station } from "services/back/infrastructure/types";
import { convertPressure } from "utils/convertePressure";
import { getRandomUid } from "utils/random";

import { Infrastructure } from "./infrastructure";
import { formatElementsDisabled } from "./utils";

const { barToAtm } = convertPressure;

type NodePointType = "node" | "mine" | "drain" | "pumping" | "source";

type NodeType = {
  uuid: string;
  x: number;
  y: number;
  isSelected?: boolean;
  pressure: number;
  deltaPressure?: number | null;
  mineId?: number;
  type: NodePointType;
  construction?: Station["construction"];
  reconstruction?: Station["reconstruction"];
  title: string;
  stationType?: "НС" | "УПН" | "УПСВ" | "УКПГ";
  startedAt: Dayjs;
  finishedAt?: Dayjs | null;
  power?: number;
  altitude: number;
  category?: "Новый";
  isDisabled: boolean;
  isDisabledDate?: boolean;
  cost?: number;
  date?: Dayjs | null;
  isFactual?: boolean;
};

const ALTITUDE_DEFAULT = 0;

class Nodes {
  data?: NodeType[];

  constructor(private parent: Infrastructure) {
    makeObservable(this, {
      data: observable,
      isLoading: computed,
      length: computed,
      mines: computed,
      drains: computed,
      sources: computed,
      minesEarliestDates: computed,
      init: action,
      pushNode: action,
      update: action,
      remove: action,
    });

    reaction(
      () => this.parent.currentDate,
      () => this.init(this.data ?? [])
    );
  }

  public init = (data: NodeType[]) => {
    this.data = formatElementsDisabled(data, this.parent.currentDate);
  };

  get isLoading() {
    return this.data === undefined;
  }

  get length() {
    return this.data?.length || 0;
  }

  get mines() {
    return this.data?.filter((el) => el.type === "mine") || [];
  }
  get drains() {
    return this.data?.filter((el) => el.type === "drain") || [];
  }
  get sources() {
    return this.data?.filter((el) => el.type === "source") || [];
  }

  get minesEarliestDates() {
    const mines = new Map<number, Dayjs>();
    this.parent.fact.wells.wells.forEach((el) => {
      if (mines.has(el.mineId)) {
        if (dayjs(el.date).isBefore(mines.get(el.mineId))) {
          mines.set(el.mineId, el.date);
        }
      } else {
        mines.set(el.mineId, el.date);
      }
    });

    return mines;
  }

  pushNode = (node: NodeType) => {
    if (this.data === undefined) {
      this.data = [node];
    } else {
      this.data?.push(node);
    }
    this.parent.markUpdated();
  };

  update = (node: Partial<NodeType>) => {
    const nodeIndex = this.data!.findIndex(({ uuid }) => uuid === node.uuid);
    this.data![nodeIndex] = { ...this.data![nodeIndex], ...node };
    this.parent.markUpdated();
  };

  static createNode = (position: { x: number; y: number }, index: number): NodeType => ({
    uuid: getRandomUid(),
    startedAt: dayjs(),
    x: position.x,
    y: position.y,
    pressure: 0,
    type: "node",
    title: `Узел ${index}`,
    altitude: ALTITUDE_DEFAULT,
    isDisabled: false,
  });

  static nodesFromPipeSystem(pipeSystem: PipeSystem, parent: Infrastructure): NodeType[] {
    const nodes = new Map<string, NodeType>(
      Array.from(pipeSystem.nodes, (node, i): [string, NodeType] => [
        node.uuid,
        {
          ...node,
          x: Number(node.x.toFixed(2)),
          y: Number(node.y.toFixed(2)),
          pressure: 0,
          type: "node",
          title: node.title || `Узел ${i + 1}`,
          startedAt: dayjs(),
          altitude: node.z ?? ALTITUDE_DEFAULT,
          isDisabled: false,
        },
      ])
    );
    // propagate stations
    for (const station of pipeSystem.stations) {
      const node = nodes.get(station.nodeUuid);
      if (!node) {
        continue;
      }
      node.type = "pumping";
      node.stationType = station.stationType;
      node.construction = station.construction;
      node.reconstruction = station.reconstruction;
      node.power = station.power;
      node.deltaPressure = barToAtm(station.deltaPressure);
      node.finishedAt = station.finishedAt ? dayjs(station.finishedAt) : null;
      if (station.startedAt) {
        node.startedAt = dayjs(station.startedAt);
      }
    }
    // drain sources
    for (const drainSource of pipeSystem.drainSources) {
      const node = nodes.get(drainSource.nodeUuid);
      if (!node) {
        continue;
      }
      node.type = drainSource.mode;
      node.date = drainSource.date ? dayjs(drainSource.date) : null;
      if (drainSource.startedAt) {
        node.startedAt = dayjs(drainSource.startedAt);
      }
    }
    // propagate mines
    for (const mineLink of pipeSystem.linksMineNode) {
      const node = nodes.get(mineLink.nodeUuid);
      if (!node) {
        continue;
      }
      node.type = "mine";
      node.mineId = mineLink.mineId;
      node.cost = mineLink.construction?.totalCostPerUnit || undefined;
      node.isFactual = mineLink.isFactual;
      node.startedAt = parent.nodes.minesEarliestDates.get(mineLink.mineId) ?? dayjs();
    }
    return [...nodes.values()];
  }

  static getStationAttributes(data?: Partial<StationType>) {
    return {
      stationType: data ? data.stationType : undefined,
      power: data ? data.power : undefined,
      deltaPressure: data ? (data.stationType ? data.deltaPressure : undefined) : undefined,
      construction: data ? data.construction : undefined,
      reconstruction: data ? data.reconstruction : undefined,
    } as Partial<NodeType>;
  }

  remove = (ids: string[]) => {
    const mineIds = this.data!.filter((item) => item.type === "mine").map((item) => item.uuid);
    const filteredIds = ids.filter((id) => !mineIds.includes(id));
    const relatedPipesIds = (this.parent.pipes.data ?? [])
      .filter((pipe) => filteredIds.includes(pipe.from) || filteredIds.includes(pipe.to))
      .map(({ uuid }) => uuid);

    this.parent.pipes.remove(relatedPipesIds);
    this.data = this.data!.filter(({ uuid }) => !filteredIds.includes(uuid));
    this.parent.markUpdated();
  };

  at = (uuid: string) => {
    const nodeIndex = (this.data ?? []).findIndex((el) => el.uuid === uuid);
    if (nodeIndex === -1) {
      return;
    }
    return this.data![nodeIndex];
  };
}

export { type NodePointType, Nodes, type NodeType };
