import { useMemo } from "react";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Button, Select, Tooltip } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Icon } from "elements/icon/icon";
import { Tree } from "elements/tree/tree";
import { useProjectContext } from "models/project/context/projectContext";
import { useForecasts } from "models/project/fact/forecast/forecasts";
import { TreeContextProvider } from "models/tree/context";

import { generateColumns } from "./models/columns";
import { InfrastructureTableModel } from "./models/tableModel";
import { InfrastructureTableStore } from "./models/tableStore";
import { ReactComponent as PipesIcon } from "./pipesIcon.svg";

import cn from "./infrastructure.module.less";

const COMPARE_INFRA = [
  {
    value: 1,
    label: <>Внешний диаметр, мм</>,
  },
  {
    value: 2,
    label: <>Толщина стенки, мм</>,
  },
  {
    value: 3,
    label: <>Протяженность, м</>,
  },
  {
    value: 4,
    label: <>Макс. давление в начале, МПа</>,
  },
  {
    value: 5,
    label: <>Макс. давление в конце, МПа</>,
  },
  {
    value: 6,
    label: <>Макс. перепад давления, МПа</>,
  },
  {
    value: 7,
    label: <>Макс. градиент давления, МПа/км</>,
  },
  {
    value: 8,
    label: <>Макс. скорость потока, м/сек</>,
  },
  {
    value: 9,
    label: <>Макс. расход жидкости, м³/сут</>,
  },
];

const Infrastructure = observer(() => {
  const forecastsStore = useForecasts()!;
  const forecasts = useMemo(() => [...forecastsStore.values!], [forecastsStore]);
  const projectContext = useProjectContext();
  const tree = projectContext.scenarioComparison.tree;

  const store = useMemo(() => new InfrastructureTableStore(tree, forecasts), [forecasts, tree]);

  const columns = generateColumns(forecasts, store.favoriteScenario, store.onFavoriteSelect);

  const model = useMemo(() => new InfrastructureTableModel(tree, store, columns), [tree, store, columns]);

  return (
    <>
      <PageFrameTitlePortal>
        <Select
          style={{ minWidth: "270px" }}
          value={(model.store as InfrastructureTableStore).currentCompareValue}
          options={COMPARE_INFRA}
          onSelect={(model.store as InfrastructureTableStore).setCurrentCompareValue}
          placeholder={"Выберите параметр сравнения"}
        />
        <Tooltip title="Показывать трубопроводы в сравниваемых сценариях">
          <Button
            onClick={() => store.onChangeShowAllPipes()}
            type={store.showAllPipes ? "primary" : "default"}
            icon={<Icon content={<PipesIcon />} viewBox="-1 4 32 32" width="30px" height="30px"></Icon>}
          ></Button>
        </Tooltip>
      </PageFrameTitlePortal>
      <TreeContextProvider value={tree}>
        <div className={cn.root}>
          <div className={cn.leftPane}>
            <Tree className={cn.tree} title="Сценарии" preloadHooks={[]} />
          </div>
          <TableContextProvider value={model}>
            <Widget headerClassName={cn.tableHeader} className={cn.table} />
          </TableContextProvider>
        </div>
      </TreeContextProvider>
    </>
  );
});

export { COMPARE_INFRA, Infrastructure };
